<template>
  <div id="reagentInOut">
    <el-dialog
      :title="reagentInOutFormTitle"
      width="680px"
      :visible.sync="reagentInOutDialogVisible"
      :close-on-click-modal="false"
      @close="reagentInOutDialogClose"
    >
      <el-form
        ref="reagentInOutFormRef"
        :model="reagentInOutForm"
        :rules="reagentInOutFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="品名" prop="name">
              <el-input v-model="reagentInOutForm.name" placeholder="请输入品名" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="reagentInOutForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期" prop="validPeriod">
              <el-date-picker v-model="reagentInOutForm.validPeriod" placeholder="请选择有效期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产商" prop="manufacturer">
              <el-input v-model="reagentInOutForm.manufacturer" placeholder="请输入生产商" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="reagentInOutForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="购进日期" prop="purchaseDate">
              <el-date-picker v-model="reagentInOutForm.purchaseDate" placeholder="请选择购进日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用日期" prop="requisitionDate">
              <el-date-picker v-model="reagentInOutForm.requisitionDate" placeholder="请选择领用日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用量" prop="receiptAmount">
              <el-input v-model="reagentInOutForm.receiptAmount" placeholder="请输入领用量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用人" prop="recipient">
              <el-input v-model="reagentInOutForm.recipient" placeholder="请输入领用人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发放人" prop="issuer">
              <el-input v-model="reagentInOutForm.issuer" placeholder="请输入发放人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用途" prop="use">
              <el-input v-model="reagentInOutForm.use" placeholder="请输入用途" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="reagentInOutDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="reagentInOutFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="品名">
        <el-input v-model="searchForm.name" placeholder="请输入品名" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="reagentInOutPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="品名" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column label="有效期">
        <template slot-scope="scope">
          <span v-if="scope.row.validPeriod">{{ scope.row.validPeriod.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="manufacturer" label="生产商" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column label="购进日期">
        <template slot-scope="scope">
          <span v-if="scope.row.purchaseDate">{{ scope.row.purchaseDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="领用日期">
        <template slot-scope="scope">
          <span v-if="scope.row.requisitionDate">{{ scope.row.requisitionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="receiptAmount" label="领用量" />
      <el-table-column prop="recipient" label="领用人" />
      <el-table-column prop="issuer" label="发放人" />
      <el-table-column prop="use" label="用途" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="reagentInOutPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addReagentInOut, deleteReagentInOut, updateReagentInOut, selectReagentInOutInfo, selectReagentInOutList } from '@/api/storage/reagentInOut'

export default {
  data () {
    return {
      reagentInOutDialogVisible: false,
      reagentInOutFormTitle: '',
      reagentInOutForm: {
        id: '',
        name: '',
        batchNo: '',
        validPeriod: '',
        manufacturer: '',
        spec: '',
        purchaseDate: '',
        requisitionDate: '',
        receiptAmount: '',
        recipient: '',
        issuer: '',
        use: ''
      },
      reagentInOutFormRules: {
        name: [{ required: true, message: '品名不能为空', trigger: ['blur', 'change']}]
      },
      reagentInOutPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      }
    }
  },
  created () {
    selectReagentInOutList(this.searchForm).then(res => {
      this.reagentInOutPage = res
    })
  },
  methods: {
    reagentInOutDialogClose () {
      this.$refs.reagentInOutFormRef.resetFields()
    },
    reagentInOutFormSubmit () {
      if (this.reagentInOutFormTitle === '试剂试药入库领用发放发详情') {
        this.reagentInOutDialogVisible = false
        return
      }
      this.$refs.reagentInOutFormRef.validate(async valid => {
        if (valid) {
          if (this.reagentInOutFormTitle === '新增试剂试药入库领用发放发') {
            await addReagentInOut(this.reagentInOutForm)
          } else if (this.reagentInOutFormTitle === '修改试剂试药入库领用发放发') {
            await updateReagentInOut(this.reagentInOutForm)
          }
          this.reagentInOutPage = await selectReagentInOutList(this.searchForm)
          this.reagentInOutDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.reagentInOutFormTitle = '新增试剂试药入库领用发放发'
      this.reagentInOutDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteReagentInOut(row.id)
        if (this.reagentInOutPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.reagentInOutPage = await selectReagentInOutList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.reagentInOutFormTitle = '修改试剂试药入库领用发放发'
      this.reagentInOutDialogVisible = true
      this.selectReagentInOutInfoById(row.id)
    },
    handleInfo (index, row) {
      this.reagentInOutFormTitle = '试剂试药入库领用发放发详情'
      this.reagentInOutDialogVisible = true
      this.selectReagentInOutInfoById(row.id)
    },
    selectReagentInOutInfoById (id) {
      selectReagentInOutInfo(id).then(res => {
        this.reagentInOutForm.id = res.id
        this.reagentInOutForm.name = res.name
        this.reagentInOutForm.batchNo = res.batchNo
        this.reagentInOutForm.validPeriod = res.validPeriod
        this.reagentInOutForm.manufacturer = res.manufacturer
        this.reagentInOutForm.spec = res.spec
        this.reagentInOutForm.purchaseDate = res.purchaseDate
        this.reagentInOutForm.requisitionDate = res.requisitionDate
        this.reagentInOutForm.receiptAmount = res.receiptAmount
        this.reagentInOutForm.recipient = res.recipient
        this.reagentInOutForm.issuer = res.issuer
        this.reagentInOutForm.use = res.use
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectReagentInOutList(this.searchForm).then(res => {
        this.reagentInOutPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectReagentInOutList(this.searchForm).then(res => {
        this.reagentInOutPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectReagentInOutList(this.searchForm).then(res => {
        this.reagentInOutPage = res
      })
    }
  }
}
</script>

<style>
</style>
