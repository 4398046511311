import axios from '@/common/axios'
import qs from 'qs'

export function addReagentInOut (data) {
  return axios({
    method: 'post',
    url: '/storage/reagentUseProvide/add',
    data: qs.stringify(data)
  })
}

export function deleteReagentInOut (id) {
  return axios({
    method: 'delete',
    url: '/storage/reagentUseProvide/delete/' + id
  })
}

export function updateReagentInOut (data) {
  return axios({
    method: 'put',
    url: '/storage/reagentUseProvide/update',
    data: qs.stringify(data)
  })
}

export function selectReagentInOutInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/reagentUseProvide/info/' + id
  })
}

export function selectReagentInOutList (query) {
  return axios({
    method: 'get',
    url: '/storage/reagentUseProvide/list',
    params: query
  })
}
